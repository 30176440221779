<ng-container *ngIf="!countySelected?.hasRegions && countySelected || countySelected?.hasRegions && regionSelected">
  <label
    *ngIf="position.isMobile"
    class="search-filters-mobile-label mb-0 position-relative mr-2"
    [ngClass]="{'d-none h-0 pointer-events-none': !isCityShown(), 'd-block': !isCityShown()}"
  >
    <select
      #citySelectRef
      required
      aria-label="City"
      class="custom-select custom-select-mobile d-block w-100 hide-arrow"
      formControlName="cityId"
      (change)="onCityChange($event.target)"
    >
      <option value="null" hidden [selected]="!citySelected">City</option>

      <ng-container *ngIf="countySelected">
        <ng-container *ngIf="citySelect.isGrouped">
          <ng-container *ngFor="let option of citySelect.options;">
            <option *ngIf="citySelectRef.value == option.value" [value]="option.value">
              {{ option.label }}
            </option>
          </ng-container>

          <optgroup [label]="nearByLabel">
            <ng-container *ngFor="let option of citySelect.options;">
              <option
                *ngIf="option.group == nearByLabel && citySelectRef.value != option.value"
                [value]="option.value"
              >
                {{ option.label }}
              </option>
            </ng-container>
          </optgroup>

          <optgroup [label]="otherLabel">
            <ng-container *ngFor="let option of citySelect.options">
              <option *ngIf="option.group == otherLabel" [value]="option.value">
                {{ option.label }}
              </option>
            </ng-container>
          </optgroup>
        </ng-container>

        <ng-container *ngIf="!citySelect.isGrouped">
          <option *ngFor="let option of citySelect.options" [value]="option.value">
            {{ option.label }}
          </option>
        </ng-container>
      </ng-container>
    </select>
  </label>

  <div
    *ngIf="position.isDesktopNavbar"
    [ngClass]="{'d-none': !isCityShown(), 'd-flex': isCityShown()}"
  >
    <label
      [ngClass]="{'visibility-hidden': stateSelected == null}"
      class="mb-0 text-left mr-3"
    >
      <ng-select
        #ngSelectCityComponent
        appNgSelectOnHover
        [items]="citySelect.options"
        [searchable]="false"
        [ngClass]="'ng-select-explore-search-title-under-logo dark'"
        groupBy="group"
        class="ng-select-explore ng-select-dropdown auto-grow hide-arrow marked"
        bindLabel="label"
        bindValue="value"
        placeholder="City"
        formControlName="cityId"
        (change)="onCityChange($event)"
      ></ng-select>
    </label>
  </div>
</ng-container>
