import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Auth } from '../../../decorators/auth.decorator';
import { CurrentUser } from '../../../decorators/current-user.decorator';
import { IsLoggedIn } from '../../../decorators/is-logged-in.decorator';
import { ModalClose } from '../../../decorators/modal-close.decorator';
import { Restaurant } from '../../../domains/restaurant';
import { User } from '../../../domains/user';
import { AdminPickerOption } from '../../../models/enums/user-admin-options';
import { Picker } from '../../../models/picker';
import { AuthenticationService } from '../../../services/authentication.service';
import { NavigatorService } from '../../../services/navigator.service';
import { RestaurantService } from '../../../services/restaurant.service';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-navbar-top-profile',
  templateUrl: './navbar-top-profile.component.html',
  styleUrls: ['../navbar.shared.scss']
})
export class NavbarTopProfileComponent implements OnInit {
  @Input() title: string;
  @Input() edited = false;
  @Input() showBack = true;
  @Input() isOwner = true;
  @Input() userFirstName: string;
  @Input() userLastName: string;
  @Input() isSubscribed = false;

  @Output() saveClick = new EventEmitter<boolean>();

  @ViewChild('hoursEditModalRef') hoursEditModalRef: ElementRef;

  @IsLoggedIn() isLoggedIn: boolean;

  @CurrentUser() currentUser: User;

  userOptionsPicker: Picker;
  restaurantAdminPath: string;
  currentPickerOptionValue: AdminPickerOption;
  userRestaurant: Restaurant;

  constructor(
    private navigatorService: NavigatorService,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private restaurantService: RestaurantService,
  ) {
  }

  ngOnInit() {
    if (this.isLoggedIn) {
      this.restaurantService.getUserTopRestaurant().subscribe(restaurant => {
        restaurant.periodsGroupAndSort();

        this.userRestaurant = restaurant;
        this.userOptionsPicker = this.userService.getPickerOptions(!!restaurant);

        if (restaurant) {
          this.restaurantAdminPath = `/admin/${restaurant.hostname}`;
        }
      });
    }

    this.currentPickerOptionValue = AdminPickerOption[this.title];
  }

  save() {
    this.saveClick.emit(true);
  }

  @ModalClose()
  @Auth()
  goToUser() {
    this.navigatorService.goToUser();
  }

  @ModalClose()
  onPickerSelected(value: string) {
    switch (value) {
      case AdminPickerOption.DASHBOARD:
        this.navigatorService.goToUrl(this.restaurantAdminPath + '/dashboard')
          .then();
        break;
      case AdminPickerOption.BRAND:
        this.navigatorService.goToUrl(this.restaurantAdminPath + '/locations')
          .then();
        break;
      case AdminPickerOption.ABOUT:
        this.navigatorService.goToUrl(this.restaurantAdminPath + '/about')
          .then();
        break;
      case AdminPickerOption.MARKETING:
        this.navigatorService.goToUrl(this.restaurantAdminPath + '/upgrade')
          .then();
        break;
      case AdminPickerOption.ACCOUNT:
        this.navigatorService.goToProfileSettings(true);
        break;
      case AdminPickerOption.PASSWORD:
        this.navigatorService.goToSettingsPage('password');
        break;
      case AdminPickerOption.LOGOUT:
        this.authenticationService.logout();
        this.navigatorService.goToHome();
        break;
    }
  }
}
