<div *appIfIsMobile class="top-input-modal safe-area-top bg-white">
  <div class="row mx-0 border-black">
    <app-search-city-input
      (input)="searchInputValue($event)"
      (selectPlace)="onSelectPlace($event)"
      inputClass="top-input-modal-input search-filters-mobile-location-input"
    ></app-search-city-input>

    <div
      *ngIf="!searchValueExists"
      class="search-city-find-me px-1-25rem safe-area-top"
    >
      <a appHrefNone class="text-black opacity-07-to-1-on-hover" (click)="onFindMe()">Find me</a>
    </div>
  </div>

  <div class="row border-black mx-0">
    <div class="col-10 px-0">
      <app-search-restaurant-input
        inputClass="top-input-modal-input search-filters-mobile-location-input"
        (selectRestaurant)="onSelectRestaurant($event)"
      ></app-search-restaurant-input>
    </div>
  </div>

  <div class="row mx-0 bg-white d-flex align-items-center pl-43px">
    <div class="icon-v2 find-me-black scale-07 find-me-position"></div>

    <app-search-filters
      *ngIf="country"
      [position]="searchFiltersPositionMobile"
      [country]="country"
    ></app-search-filters>
  </div>
</div>
