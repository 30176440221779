import { Exclude, Expose, Transform, Type } from 'class-transformer';
import { HashUtils } from '../utils/hash-utils';
import { LocationType } from './enums/location-type';
import { GeoLocation } from './geo-location';

@Exclude()
export class SearchParams {
  @Expose()
  path: string = null;

  @Expose()
  locationId: number = null;

  @Expose()
  locationType: LocationType;

  @Expose()
  @Transform(stateId => stateId ? +stateId : null, {toClassOnly: true})
  stateId = null;

  @Expose()
  @Transform(countyId => countyId ? +countyId : null, {toClassOnly: true})
  countyId = null;

  @Expose()
  @Transform(regionId => regionId ? +regionId : null, {toClassOnly: true})
  regionId = null;

  @Expose()
  @Transform(cityId => cityId ? +cityId : null, {toClassOnly: true})
  cityId = null;

  @Expose()
  @Transform(districtId => districtId ? +districtId : null, {toClassOnly: true})
  districtId = null;

  @Expose()
  @Type(() => GeoLocation)
  geoLocation: GeoLocation = null;

  @Expose()
  streetName: string;

  @Expose()
  locationName: string;

  @Expose()
  stateKey: string;

  @Expose()
  countyName: string;

  get hash(): string {
    return HashUtils.getHashFromObject(this);
  }

  get locationNameAndStateKey(): string {
    return this.locationName + ', ' + this.stateKey.toUpperCase();
  }
}
