<div
  *ngIf="!isMobile"
  class="form-control-style bg-container"
  (mouseover)="inputHover = true"
  (mouseleave)="setInputHover(false)"
>
  <ng-container *ngIf="!inputHover">
    <ng-container *ngTemplateOutlet="searchIcon"></ng-container>
  </ng-container>

  <div class="row m-0 p-0 d-flex justify-content-between">
    <div
      *ngIf="inputHover"
      [ngClass]="{'bg-container': inputHover}"
      class="search-container"
      (mouseover)="leftInputHover = true"
      (mouseleave)="leftInputHover = false"
    >
      <ng-container *ngIf="!restaurantInputValue && inputHover">
        <ng-container *ngTemplateOutlet="searchIcon"></ng-container>
      </ng-container>

      <app-search-restaurant-input
        [inputHover]="leftInputHover"
        [mainInputHover]="inputHover"
        class="w-100"
        (input)="onRestaurantInput($event)"
      ></app-search-restaurant-input>
    </div>

    <div
      *ngIf="inputHover"
      [ngClass]="{'bg-container': inputHover}"
      class="search-container"
      (mouseover)="rightInputHover = true"
      (mouseleave)="rightInputHover = false"
    >
      <ng-container *ngIf="!cityInputValue && inputHover">
        <ng-container *ngTemplateOutlet="searchIcon"></ng-container>
      </ng-container>

      <app-search-city-input
        [inputHover]="rightInputHover"
        [mainInputHover]="inputHover"
        class="w-100"
        (selectPlace)="onSelectPlace($event)"
        (onFindMeClick)="onFindMe()"
        (input)="onCityInput($event)"
      ></app-search-city-input>
    </div>
  </div>
</div>

<ng-template #searchIcon>
  <div class="icon-search-container position-absolute flex-content-vc">
    <i class="icon-v2 search-black-new ml-3 scale-0-7"></i>
  </div>
</ng-template>

<ng-template #searchCityModalRef>
  <app-search-location-modal
    (selectPlace)="onSelectPlace($event)"
    (findMe)="onFindMe()"
    (selectRestaurant)="onSelectRestaurant($event)"
  ></app-search-location-modal>
</ng-template>
