import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Auth } from '../../../../decorators/auth.decorator';
import { IsLoggedIn } from '../../../../decorators/is-logged-in.decorator';
import { Subscribe } from '../../../../decorators/subscribe.decorator';
import { RestaurantExplore } from '../../../../domains/restaurant-explore';
import { SearchModalState } from '../../../../models/enums/search-modal-state';
import { LocationFavorites } from '../../../../models/location-favorites';
import { SearchResult } from '../../../../models/search-result';
import { SegmentedButton } from '../../../../models/segmented-button';
import { SearchModalStateWrapper } from '../../../../models/wrappers/search-modal-state.wrapper';
import { NotificationService } from '../../../../services/notification.service';
import { ShareService } from '../../../../services/share.service';

@Component({
  selector: 'app-search-modal-footer',
  templateUrl: './search-modal-footer.component.html',
  styleUrls: ['./search-modal-footer.component.scss']
})
export class SearchModalFooterComponent implements OnInit, OnChanges {
  @Input() searchResult: SearchResult;
  @Input() searchModalState: SearchModalState;
  @Input() locationFavorites: LocationFavorites;

  @Output() restaurantClicked = new EventEmitter<RestaurantExplore>();
  @Output() onStatusChange = new EventEmitter<SearchModalState>();
  @Output() openSearchModal = new EventEmitter<boolean>();

  @ViewChild('searchCityModalRef') searchCityModalRef: ElementRef;
  @ViewChild('videoModalRef') videoModalRef: ElementRef;

  @IsLoggedIn() isLoggedIn: boolean;

  searchModalStateWrapper: SearchModalStateWrapper;
  activeSegmentedButton = 1;

  segments = [];

  constructor(
    private shareService: ShareService,
    private notificationService: NotificationService
  ) {
  }

  ngOnInit(): void {
    this.searchModalStateWrapper = new SearchModalStateWrapper(this.searchModalState);

    this.initButtons();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('locationFavorites')) {
      this.initButtons();
    }
  }

  onClickShare() {
    this.shareService.shareUrl(location.href);
  }

  openCitySearchModal() {
    this.openSearchModal.emit(true);
  }

  onFilterClick() {
    this.notificationService.onFilterClicked();
  }

  statusChange(segmentedButton: SegmentedButton) {
    this.searchModalStateWrapper.value = segmentedButton.value;

    this.onStatusChange.emit(this.searchModalStateWrapper.value);
  }

  @Auth()
  @Subscribe()
  openMembershipModal() {}

  private initButtons() {
    this.segments = [
      new SegmentedButton(
        'Favorites', SearchModalState.FAVORITE, this.locationFavorites?.favoritesCount,
        !!this.locationFavorites?.tryMeCount
      ),
      new SegmentedButton('Videos', SearchModalState.VIDEO)
    ];

    this.segments.forEach((segment, index) => {
      if (segment.value === this.searchModalState) {
        this.activeSegmentedButton = index;
      }
    });
  }
}
