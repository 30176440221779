import { Subscription } from 'rxjs';
import { ModalType } from '../models/enums/modal-type';
import { DecoratorInjectorService } from '../services/decorator/decorator-injector.service';
import { DecoratorService } from '../services/decorator/decorator.service';

export function AreModalsOpen(modalTypes: ModalType[]): PropertyDecorator {
  return (target: any, propertyKey: string) => {
    let service: DecoratorService;
    const isOpen: boolean[] = [];
    const subscribers: Subscription[] = [];
    let areOpen: boolean;

    const getter = () => {
      if (areOpen !== undefined) {
        return areOpen;
      }

      if (service === undefined) {
        service = DecoratorInjectorService.getService();
      }

      if (subscribers.length === 0) {
        modalTypes.forEach(modalType => {
          subscribers[modalType] = service.modalService.modalIsOpenSubjects[modalType].subscribe(isOpenValue => {
            isOpen[modalType] = isOpenValue;
          });
        });

        areOpen = isOpen.every(isOpenValue => isOpenValue);
      }

      areOpen = modalTypes.every(modalType => service.modalService.modalIsOpenSubjects[modalType].getValue());

      return areOpen;
    };

    const setter = (newValue) => {
      areOpen = newValue;
    };

    Object.defineProperty(target, propertyKey, {
      get: getter,
      set: setter
    });
  };
}
