import { Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { CurrentUser } from '../../../decorators/current-user.decorator';
import { IsDevice } from '../../../decorators/is-device.decorator';
import { IsLoggedIn } from '../../../decorators/is-logged-in.decorator';
import { ModalClose } from '../../../decorators/modal-close.decorator';
import { Period } from '../../../domains/period';
import { Restaurant } from '../../../domains/restaurant';
import { User } from '../../../domains/user';
import { DeviceType } from '../../../models/enums/device-type';
import { ModalType } from '../../../models/enums/modal-type';
import { PeriodType } from '../../../models/period/period-type';
import { PeriodTypeOption } from '../../../models/period/period-type-option';
import { PeriodTypeWrapper } from '../../../models/period/period-type-wrapper';
import { Time } from '../../../models/period/time';
import { UnsplashImage } from '../../../models/unsplash-image';
import { ModalService } from '../../../services/modal.service';
import { NotificationService } from '../../../services/notification.service';
import { PeriodService } from '../../../services/period.service';
import { RestaurantService } from '../../../services/restaurant.service';
import { TimeoutService } from '../../../services/timeout.service';
import { UnsplashService } from '../../../services/unsplash.service';
import { DateTransform } from '../../../utils/date-transform';
import { DateUtils } from '../../../utils/date-utils';

@Component({
  selector: 'app-hours-edit-modal',
  templateUrl: './hours-edit-modal.component.html',
  styleUrls: [
    './hours-edit-modal.component.scss',
    '../../navbar/navbar.shared.scss'
  ]
})
export class HoursEditModalComponent implements OnInit {
  @Input() restaurant: Restaurant;
  @Input() explore = false;
  @Input() dashboard = false;

  @Output() editRestaurantMenus = new EventEmitter<Restaurant>();

  @ViewChild('hoursEditModalRef') hoursEditModalRef: ElementRef;
  @ViewChild('eventAddModalRef') eventAddModalRef: ElementRef;

  @IsDevice(DeviceType.DESKTOP) isDesktop: boolean;

  @CurrentUser() currentUser: User;

  @IsLoggedIn() isLoggedIn: boolean;

  userRestaurants: Restaurant[] = [];
  isAdmin = false;
  timeListStart: Time[] = [];
  timeListEnd: Time[] = [];
  timeStartSelected: any;
  timeEndSelected: Time;
  backgroundImage: string | UnsplashImage;
  allDaySelected = false;
  periodTypeOptions: PeriodTypeOption[] = [];
  periodTypeOption: PeriodTypeOption;
  today: string;
  closedUntilMin: string;
  dateSelected: string;
  closedFromSelected: string;
  closedUntilSelected: string;
  period: Period;

  dateRangeOptions = {
    connect: true,
    step: 1,
    range: {
      min: 1,
      max: 7
    },
    direction: 'ltr'
  };

  dateRangeSelected = [1, 7];

  tooltips = [
    {to: (value: string) => DateUtils.WEEK_DAYS[parseInt(value, 0)]},
    {to: (value: string) => DateUtils.WEEK_DAYS[parseInt(value, 0)]}
  ];

  delayed = false;

  constructor(
    private periodService: PeriodService,
    private timeoutService: TimeoutService,
    private modalService: ModalService,
    private unsplashService: UnsplashService,
    private notificationService: NotificationService,
    private ngZone: NgZone,
    private restaurantService: RestaurantService
  ) {
    for (const regularPeriodTypeKey in PeriodType) {
      if (regularPeriodTypeKey !== PeriodType.SPECIAL) {
        const periodType = new PeriodTypeWrapper(regularPeriodTypeKey);
        this.periodTypeOptions.push(new PeriodTypeOption(periodType));

        if (periodType.isClosed) {
          this.periodTypeOptions.push(new PeriodTypeOption(periodType, null, true));
        }
      }
    }

    this.periodTypeOption = this.periodTypeOptions.find(
      periodSelectOption => periodSelectOption.type.isEvent
    );

    const timeStart = [4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 0, 1, 2, 3];

    timeStart.forEach(hour => {
      [0, 30].forEach(minute => {
        this.timeListStart.push(new Time(hour, minute));

        this.periodTypeOptions.push(
          new PeriodTypeOption(new PeriodTypeWrapper(PeriodType.SPECIAL), new Time(hour, minute))
        );
      });
    });

    const timeEnd = [11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    timeEnd.forEach(hour => {
      [0, 30].forEach(minute => {
        this.timeListEnd.push(new Time(hour, minute));
      });
    });

    this.timeStartSelected = this.timeListStart.find(time => time.hour === 10 && time.minute === 30);
    this.timeEndSelected = this.timeListEnd.find(time => time.hour === 14 && time.minute === 0);
  }

  ngOnInit() {
    if (this.isLoggedIn) {
      this.restaurantService.getCurrentUserRestaurants().subscribe(restaurants => {
        this.userRestaurants = restaurants;
      });
    }

    const dropdown = document.querySelector('.dropdown-hover');

    dropdown?.addEventListener('mouseover', () => {
      const select = dropdown.querySelector('select');
      select.focus();
      select.click();
    });

    this.unsplashService.randomPhoto().subscribe(value => {
      this.backgroundImage = value;
    });

    setTimeout(() => {
      this.delayed = true;
    }, 100);

    this.isAdmin = this.currentUser?.isAdmin(this.restaurant);

    this.today = DateUtils.dateToString();
    this.dateSelected = this.today;
    this.closedFromSelected = this.today;

    this.setClosedUntil();
  }

  @ModalClose()
  modalClose() {
  }

  addPeriod() {
    const period = new Period();
    period.type = this.periodTypeOption.type;

    if (this.periodTypeOption.type.isNormalHours) {
      if (this.timeStartSelected === this.timeEndSelected) {
        this.timeStartSelected = '24hours';
      }

      if (this.timeStartSelected === '24hours') {
        this.timeStartSelected = new Time(0, 0);
        this.timeEndSelected = new Time(23, 59);
      }

      period.timeStart = this.timeStartSelected;
      period.timeEnd = this.timeEndSelected;
      period.weekDayStart = this.dateRangeSelected[0];
      period.weekDayEnd = this.dateRangeSelected[1];
    } else {
      if (this.periodTypeOption.type.isEvent || this.periodTypeOption.type.isSpecialHours) {
        period.date = DateUtils.stringToDate(this.dateSelected);
      }

      if (this.periodTypeOption.type.isEvent || this.periodTypeOption.type.isSpecialHours) {
        period.timeStart = this.timeStartSelected;
        period.timeEnd = this.timeEndSelected;
      }

      if (this.periodTypeOption.type.isClosed) {
        period.closedFrom = DateUtils.stringToDate(this.closedFromSelected);

        if (this.periodTypeOption.closedUntil) {
          period.closedUntil = DateUtils.stringToDate(this.closedUntilSelected);
        } else {
          period.closedUntil = period.closedFrom;
        }
      }
    }

    this.period = period;

    if (!this.periodTypeOption.type.isEvent) {
      this.periodService.add(period).subscribe(addedPeriod => {
        period.id = addedPeriod.id;
        this.restaurant.periods.push(period);
        this.restaurant.periodsGroupAndSort();
      });
    } else {
      this.modalService.open(
        this.eventAddModalRef, !this.isDesktop ? ModalType.GRAY_TRANSPARENT : ModalType.LOGIN
      );
    }
  }

  addPeriodTypeEvent(period: Period) {
    this.periodService.add(this.period).subscribe(addedPeriod => {
      this.period.id = addedPeriod.id;
      this.restaurant.periods.push(this.period);
      this.restaurant.periodsGroupAndSort();
      this.period = null;
      this.close();

      if (period.timeEnd) {
        this.notificationService.addPeriodClicked(period);
      }
    });
  }

  removePeriod(id: number) {
    this.ngZone.run(() => {
      const periodToRemove = this.restaurant.periods.find(period => period.id === id);

      if (periodToRemove !== undefined) {
        periodToRemove.removed = true;

        this.periodService.delete(periodToRemove).subscribe(() => {
          this.timeoutService.setTimeoutWithRelayRace(() => {
            this.restaurant.periods.forEach(period => {
              if (period.removed) {
                this.restaurant.periods.splice(
                  this.restaurant.periods.indexOf(period),
                  1
                );
              }
            });

            this.restaurant.periodsGroupAndSort();
          }, 2000);
        });
      }
    });
  }

  @ModalClose()
  close() {
  }

  onClosedFromChange() {
    this.setClosedUntil();
  }

  private setClosedUntil() {
    const closedFromDate = DateUtils.stringToDate(this.closedFromSelected);
    const closedFromNextDay = DateTransform.datePlus(closedFromDate, 1);
    this.closedUntilMin = DateUtils.dateToString(closedFromNextDay);

    if (this.closedUntilSelected) {
      const closedUntil = DateUtils.stringToDate(this.closedUntilSelected);

      if (closedFromNextDay > closedUntil) {
        this.closedUntilSelected = this.closedUntilMin;
      }
    } else {
      this.closedUntilSelected = this.closedUntilMin;
    }
  }

  onStartTimeChange(time: any) {
    this.allDaySelected = time === '24hours';
    this.timeStartSelected = time;
  }

  periodTypeOptionSelected(option: any) {
    this.periodTypeOption = option;

    if (this.periodTypeOption.type.isSpecialHours) {
      this.timeStartSelected = this.periodTypeOption.specialTimeStart;
    }
  }

  showDatePicker(event) {
    event.target.showPicker();
  }

  @ModalClose()
  closeModal() {}

  openMenuEdit(restaurant: Restaurant) {
    this.editRestaurantMenus.emit(restaurant);

    this.closeModal();
  }
}
