import { Component } from '@angular/core';
import { SearchCityService } from '../../../../services/search-city.service';

@Component({
  selector: 'app-navbar-bottom-icon-search',
  templateUrl: './navbar-bottom-icon-search.component.html',
  styles: [
  ]
})
export class NavbarBottomIconSearchComponent {
  constructor(private searchCityService: SearchCityService) {
  }

  openCitySearchModal() {
    this.searchCityService.openSearchCityModalSubject.next(true);
  }
}
