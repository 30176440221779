<ng-container *appIfIsMobile>
  <div *ngIf="position.isMobile" class="search-filters-mobile">
    <form [formGroup]="searchFormGroup.searchForm">
      <div class="row font-size-0-96rem mx-1-25rem">
        <div class="col-12 d-flex px-0">
          <input ngbAutofocus type="hidden"/>

          <app-state-select
            *ngIf="countrySelected"
            [position]="position"
            [stateSelect]="stateSelect"
            [countrySelected]="countrySelected"
            [stateSelected]="stateSelected"
            (stateChange)="onStateChange($event)"
          ></app-state-select>

          <app-county-select
            *ngIf="stateSelected"
            [position]="position"
            [countySelect]="countySelect"
            [countySelected]="countySelected"
            [stateSelected]="stateSelected"
            (countyChange)="onCountyChange($event)"
          ></app-county-select>

          <app-region-select
            [position]="position"
            [regionSelect]="regionSelect"
            [regionSelected]="regionSelected"
            [countySelected]="countySelected"
            (regionChange)="onRegionChange($event)"
          ></app-region-select>

          <app-city-select
            [position]="position"
            [citySelect]="citySelect"
            [citySelected]="citySelected"
            [countySelected]="countySelected"
            [regionSelected]="regionSelected"
            [stateSelected]="stateSelected"
            (cityChange)="onCityChange($event)"
          ></app-city-select>

          <app-district-select
            [position]="position"
            [districtSelect]="districtSelect"
            [districtSelected]="districtSelected"
            [citySelected]="citySelected"
            [stateSelected]="stateSelected"
            (districtChange)="onDistrictChange($event)"
          ></app-district-select>
        </div>
      </div>
    </form>
  </div>

  <div *ngIf="position.asTitle" class="search-filters-mobile">
    <form [formGroup]="searchFormGroup.searchForm">
      <input ngbAutofocus type="hidden"/>

      <label
        *ngIf="!countySelected?.hasRegions && countySelected || countySelected?.hasRegions && regionSelected"
        class="search-filters-mobile-label mb-0 d-block position-relative"
      >
        <ng-container *ngIf="!citySelected?.bigCity">
          <ng-container *ngIf="citySelect.hasSingleOption()">
            <span class="custom-select-navbar-title">
              {{ citySelect.options[0].label }}
            </span>
          </ng-container>

          <ng-container *ngIf="citySelect.hasMultipleOptions()">
            <select
              #titleCitySelectRef
              [appSelectWidthByText]="true"
              required
              aria-label="City"
              class="custom-select-navbar-title custom-select custom-select-mobile d-block w-100 hide-arrow"
              formControlName="cityId"
              (change)="onCityChange($event.target)"
            >
              <ng-container *ngIf="countySelected">
                <option *ngFor="let option of citySelect.options" [value]="option.value">
                  {{ option.label }}
                </option>
              </ng-container>
            </select>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="citySelected?.bigCity">
          <ng-container *ngIf="districtSelect.hasSingleOption()">
            <span class="custom-select-navbar-title">
              {{ districtSelect.options[0].label }}
            </span>
          </ng-container>

          <ng-container *ngIf="districtSelect.hasMultipleOptions()">
            <select
              #titleDistrictSelectRef
              [appSelectWidthByText]="true"
              required
              aria-label="City"
              class="custom-select-navbar-title custom-select custom-select-mobile d-block w-100 hide-arrow"
              formControlName="districtId"
              (change)="onDistrictChange($event.target)"
            >
              <ng-container *ngIf="citySelected">
                <option *ngFor="let option of districtSelect.options" [value]="option.value">
                  {{ option.label }}
                </option>
              </ng-container>
            </select>
          </ng-container>
        </ng-container>
      </label>
    </form>
  </div>
</ng-container>

<div *appIfIsDesktop class="search-filters-desktop">
  <form *ngIf="position.isDesktopNavbar && countrySelected" [formGroup]="searchFormGroup.searchForm">
    <div class="search-filters-desktop-under-logo row text-black">
      <app-state-select
        [position]="position"
        [stateSelect]="stateSelect"
        [countrySelected]="countrySelected"
        [stateSelected]="stateSelected"
        (stateChange)="onStateChange($event)"
      ></app-state-select>

      <app-county-select
        [position]="position"
        [countySelect]="countySelect"
        [countySelected]="countySelected"
        [stateSelected]="stateSelected"
        (countyChange)="onCountyChange($event)"
      ></app-county-select>

      <app-region-select
        [position]="position"
        [regionSelect]="regionSelect"
        [regionSelected]="regionSelected"
        [countySelected]="countySelected"
        (regionChange)="onRegionChange($event)"
      ></app-region-select>

      <app-city-select
        [position]="position"
        [citySelect]="citySelect"
        [citySelected]="citySelected"
        [countySelected]="countySelected"
        [regionSelected]="regionSelected"
        [stateSelected]="stateSelected"
        (cityChange)="onCityChange($event)"
      ></app-city-select>


      <app-district-select
        [position]="position"
        [districtSelect]="districtSelect"
        [districtSelected]="districtSelected"
        [citySelected]="citySelected"
        [stateSelected]="stateSelected"
        (districtChange)="onDistrictChange($event)"
      ></app-district-select>
    </div>
  </form>
</div>
