import { Type } from 'class-transformer';
import { Chain } from './chain';
import { RestaurantExplore } from './restaurant-explore';
import { TypeCategorySimple } from './type-category-simple';

export class TypeCategory extends TypeCategorySimple {
  @Type(() => RestaurantExplore)
  restaurants: RestaurantExplore[] = [];

  @Type(() => Chain)
  chains: Chain[] = [];

  get hasRestaurants() {
    return this.restaurants.length > 0;
  }
}
