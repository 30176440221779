import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Subscription } from 'rxjs';
import { IsDevice } from '../../../../decorators/is-device.decorator';
import { CityWithChild } from '../../../../domains/city-with-child';
import { CountyWithChild } from '../../../../domains/county-with-child';
import { RegionWithChild } from '../../../../domains/region-with-child';
import { StateWithChild } from '../../../../domains/state-with-child';
import { DeviceType } from '../../../../models/enums/device-type';
import { Labels } from '../../../../models/enums/label';
import { Select } from '../../../../models/select';
import { SearchFiltersPositionWrapper } from '../../../../models/wrappers/search-filters-position.wrapper';
import { SelectService } from '../../../../services/select.service';

@Component({
  selector: 'app-city-select',
  templateUrl: './city-select.component.html',
  styles: [
  ],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class CitySelectComponent implements OnInit {
  @Input() position: SearchFiltersPositionWrapper;
  @Input() citySelect: Select;
  @Input() stateSelected: StateWithChild;
  @Input() countySelected: CountyWithChild;
  @Input() regionSelected: RegionWithChild;
  @Input() citySelected: CityWithChild;

  @Output() cityChange = new EventEmitter<any>();

  @ViewChild('ngSelectCityComponent') ngSelectCityComponent: NgSelectComponent;
  @ViewChild('citySelectRef') citySelectRef: ElementRef;

  @IsDevice(DeviceType.MOBILE) isMobile: boolean;

  nearByLabel = Labels.NEARBY_LABEL;
  otherLabel = Labels.OTHER_LABEL;

  openNgSelectSubscription: Subscription;
  toggleNgSelectSubscription: Subscription;

  constructor(private selectService: SelectService) {
  }

  ngOnInit() {
    this.openNgSelectSubscription = this.citySelect.openNgSelectSubject.subscribe(() => {
      this.ngSelectCityComponent.open();
    });

    this.toggleNgSelectSubscription = this.citySelect.toggleNgSelectSubject.subscribe(disable => {
      if (this.isMobile) {
        this.selectService.toggleSelect(this.citySelectRef, disable);
      } else {
        this.selectService.toggleNgSelectComponent(this.ngSelectCityComponent, disable);
      }
    });
  }

  onCityChange($event: any) {
    this.cityChange.emit($event);
  }

  isCityShown() {
    return this.citySelect.hasMultipleOptions() && (!this.regionSelected || !this.citySelected?.bigCity);
  }
}
