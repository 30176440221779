<a
  appHrefNone
  class="icon-main-circle-dark-container"
>
  <app-picker
    *ngIf="isLoggedIn && isSubscribed"
    [classes]="'icon main-circle-dark'"
    [picker]="picker"
    (selectedValue)="onRecentRestaurantChanged($event)"
  ></app-picker>
</a>
