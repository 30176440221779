import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { Auth } from '../../../decorators/auth.decorator';
import { CurrentSearchParams } from '../../../decorators/current-search-params.decorator';
import { IsLoggedIn } from '../../../decorators/is-logged-in.decorator';
import { LocalStorageValue } from '../../../decorators/local-storage-value.decorator';
import { Chain } from '../../../domains/chain';
import { Restaurant } from '../../../domains/restaurant';
import { RestaurantExplore } from '../../../domains/restaurant-explore';
import { BooleanLocalStorage } from '../../../local-storage/boolean-local-storage';
import { LocalStorageKey } from '../../../models/enums/local-storage-key';
import { ModalType } from '../../../models/enums/modal-type';
import { LocationFavorites } from '../../../models/location-favorites';
import { Picker } from '../../../models/picker';
import { PickerConfig } from '../../../models/picker-config';
import { PickerGroup } from '../../../models/picker-group';
import { SearchParams } from '../../../models/search-params';
import { SearchResult } from '../../../models/search-result';
import { ModalService } from '../../../services/modal.service';
import { NavigatorService } from '../../../services/navigator.service';
import { RestaurantService } from '../../../services/restaurant.service';
import { RouterService } from '../../../services/router.service';

@Component({
  selector: 'app-navbar-bottom-explore',
  templateUrl: './navbar-bottom-explore.component.html',
  styleUrls: [
    '../navbar.shared.scss'
  ]
})
export class NavbarBottomExploreComponent implements OnInit {
  @Input() expanded: boolean;
  @Input() searchResult: SearchResult;
  @Input() locationFavorites: LocationFavorites;

  @Output() mainMenuClick = new EventEmitter<boolean>();
  @Output() toggleButtonClick = new EventEmitter<boolean>();
  @Output() favoritesClick = new EventEmitter<boolean>();
  @Output() videoClick = new EventEmitter<boolean>();
  @Output() onRestaurantClicked = new EventEmitter<RestaurantExplore>();
  @Output() editRestaurantClicked = new EventEmitter<Restaurant>();

  @ViewChild('hoursEditModalRef') hoursEditModalRef: ElementRef;

  @IsLoggedIn() isLoggedIn: boolean;

  @LocalStorageValue(BooleanLocalStorage, LocalStorageKey.IS_EDIT_MODE, {restaurant: true, user: true})
  isEditModeLocalStorage: BooleanLocalStorage;

  @CurrentSearchParams() searchParams: SearchParams;

  isEditMode: boolean;
  userRestaurants: Restaurant[] = [];
  userRestaurantsPicker: Picker;
  restaurant: Restaurant;
  canEditHours = false;

  constructor(
    private restaurantService: RestaurantService,
    private routerService: RouterService,
    private modalService: ModalService,
    private navigatorService: NavigatorService
  ) {
  }

  ngOnInit() {
    this.isEditMode = this.isEditModeLocalStorage.getItem();

    if (this.isLoggedIn) {
      this.restaurantService.getCurrentUserRestaurants().subscribe(restaurants => {
        this.userRestaurants = restaurants;

        this.setCanEditHours();

        const pickerGroups = [];

        this.userRestaurants.forEach((restaurant: Restaurant) => {
          const pickerGroup = new PickerGroup(restaurant.nameView);
          pickerGroup.addOption(restaurant.id.toString(), restaurant.localityDisplay, restaurant);
          pickerGroups.push(pickerGroup);
        });

        this.userRestaurantsPicker = new Picker(plainToClass(PickerConfig, {groups: pickerGroups}));
      });
    }
  }

  private setCanEditHours() {
    this.canEditHours = !this.searchParams.geoLocation &&
      !this.isEditMode &&
      this.isLoggedIn &&
      this.userRestaurants?.length > 0;
  }

  @Auth()
  onFavoritesClick() {
    this.favoritesClick.emit(true);
  }

  onVideoClick() {
    this.videoClick.emit(true);
  }

  onRestaurantsPickerSelected(restaurant: RestaurantExplore) {
    this.onRestaurantClicked.emit(restaurant);
  }

  openHoursEditModal(restaurant: Restaurant) {
    this.restaurant = restaurant;
    this.routerService.setRestaurantHostnameAndLocationId(restaurant?.hostname, restaurant?.locationIndex);
    const modal = this.modalService.open(this.hoursEditModalRef, ModalType.HOURS_EDIT);

    modal.onClose().then(() => {
      this.routerService.setRestaurantHostnameAndLocationId(null, null);
    });
  }

  editRestaurantMenus(event) {
    this.editRestaurantClicked.emit(event);
  }

  onChainsPickerSelected(chain: Chain) {
    this.navigatorService.goToMaps(
      `${chain.title}, ${this.searchParams.locationNameAndStateKey}`
    );
  }
}
