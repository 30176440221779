import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { CountryWithChild } from '../domains/country-with-child';

@Injectable({providedIn: 'root'})
export class SearchCityService {
  countrySubject: BehaviorSubject<CountryWithChild> = new BehaviorSubject<CountryWithChild>(null);
  openSearchCityModalSubject: Subject<boolean> = new Subject<boolean>();
  selectRestaurantSubject: Subject<boolean> = new Subject<boolean>();
  findMeSubject: Subject<boolean> = new Subject<boolean>();
}
