import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { IsDevice } from '../../../decorators/is-device.decorator';
import { LocationEmbed } from '../../../domains/location-embed';
import { Restaurant } from '../../../domains/restaurant';
import { RestaurantEmbed } from '../../../domains/restaurant-embed';
import { RestaurantSimple } from '../../../domains/restaurant-simple';
import { DeviceType } from '../../../models/enums/device-type';
import { TourType } from '../../../models/enums/tour-type';

@Component({
  selector: 'app-video-modal',
  templateUrl: 'video-modal.component.html',
  styleUrls: ['video-modal.component.scss']
})
export class VideoModalComponent implements OnInit, OnChanges {
  @Input() restaurant: Restaurant | RestaurantSimple;
  @Input() type: TourType;

  @Output() videoAdded = new EventEmitter<RestaurantEmbed | LocationEmbed>();

  @IsDevice(DeviceType.DESKTOP) isDesktop: boolean;

  TourType = TourType;

  submitClicked = false;

  constructor() {
  }

  ngOnInit(): void {
    this.submitClicked = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('type')) {
      this.type = changes.type.currentValue;
    }
  }

  onVideoAdded (event: RestaurantEmbed | LocationEmbed) {
    this.videoAdded.emit(event);
  }
}
