import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Subscription } from 'rxjs';
import { IsDevice } from '../../../../decorators/is-device.decorator';
import { CityWithChild } from '../../../../domains/city-with-child';
import { District } from '../../../../domains/district';
import { StateWithChild } from '../../../../domains/state-with-child';
import { DeviceType } from '../../../../models/enums/device-type';
import { Labels } from '../../../../models/enums/label';
import { Select } from '../../../../models/select';
import { SearchFiltersPositionWrapper } from '../../../../models/wrappers/search-filters-position.wrapper';
import { SelectService } from '../../../../services/select.service';

@Component({
  selector: 'app-district-select',
  templateUrl: './district-select.component.html',
  styles: [
  ],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class DistrictSelectComponent implements OnInit, OnDestroy {
  @Input() position: SearchFiltersPositionWrapper;
  @Input() districtSelect: Select;
  @Input() stateSelected: StateWithChild;
  @Input() citySelected: CityWithChild;
  @Input() districtSelected: District;

  @Output() districtHover = new EventEmitter<boolean>();
  @Output() districtChange = new EventEmitter<any>();

  @ViewChild('districtSelectRef') districtSelectRef: ElementRef;
  @ViewChild('ngSelectDistrictComponent') ngSelectDistrictComponent: NgSelectComponent;

  @IsDevice(DeviceType.MOBILE) isMobile: boolean;

  nearByLabel = Labels.NEARBY_LABEL;
  otherLabel = Labels.OTHER_LABEL;

  subscription: Subscription;
  toggleNgSelectSubscription: Subscription;

  constructor(private selectService: SelectService) {
  }

  ngOnInit() {
    this.subscription = this.districtSelect.openNgSelectSubject.subscribe(() => {
      this.ngSelectDistrictComponent.open();
    });

    this.toggleNgSelectSubscription = this.districtSelect.toggleNgSelectSubject.subscribe(disable => {
      if (this.isMobile) {
        this.selectService.toggleSelect(this.districtSelectRef, disable);
      } else {
        this.selectService.toggleNgSelectComponent(this.ngSelectDistrictComponent, disable);
      }
    });
  }

  ngOnDestroy() {
    this.toggleNgSelectSubscription.unsubscribe();
    this.subscription.unsubscribe();
  }

  onDistrictChange($event: any) {
    this.districtChange.emit($event);
  }
}
