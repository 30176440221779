import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { CountryWithChild } from '../../../../domains/country-with-child';
import { StateWithChild } from '../../../../domains/state-with-child';
import { Select } from '../../../../models/select';
import { SearchFiltersPositionWrapper } from '../../../../models/wrappers/search-filters-position.wrapper';

@Component({
  selector: 'app-state-select',
  templateUrl: './state-select.component.html',
  styles: [
  ],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class StateSelectComponent implements OnInit {
  @Input() position: SearchFiltersPositionWrapper;
  @Input() stateSelect: Select;
  @Input() countrySelected: CountryWithChild;
  @Input() stateSelected: StateWithChild;

  @Output() stateChange = new EventEmitter<any>();

  ngOnInit() {
  }

  onStateChange($event: any) {
    this.stateChange.emit($event);
  }
}
