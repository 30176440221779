import { plainToClass, Type } from 'class-transformer';
import { Subject } from 'rxjs';
import { MathUtils } from '../utils/math-utils';
import { Labels } from './enums/label';
import { SortType } from './enums/sort-type';
import { GeoLocation } from './geo-location';
import { SelectOption } from './select-option';

export class Select {
  readonly NEAREST_COUNT = 6;

  openNgSelectSubject = new Subject<boolean>();
  toggleNgSelectSubject = new Subject<boolean>();

  @Type(() => SelectOption)
  options: SelectOption[] = [];

  get isEmpty() {
    return this.options.length === 0;
  }

  hasSingleOption() {
    return this.options.length === 1;
  }

  hasMultipleOptions() {
    return this.options.length > 1;
  }

  get isGrouped() {
    return this.options.every(option => option.group && option.nearest);
  }

  addOption(value: any, label: string, options: {geoLocation?: GeoLocation, nearest?: number, group?: string} = {}) {
    this.options = [...this.options, plainToClass(SelectOption,{value, label, ...options})];
  }

  sort() {
    this.options.sort((a, b) => a.label.localeCompare(b.label));
  }

  sortOptionsByNearest(
    geoLocation: GeoLocation,
    sortType: SortType = SortType.ALPHABETICALLY
  ) {
    this.options.forEach(option => {
      if (option.geoLocation) {
        option.relativeDistance = MathUtils.distance(
          geoLocation.latitude,
          geoLocation.longitude,
          option.geoLocation.latitude,
          option.geoLocation.longitude
        );
      } else {
        option.relativeDistance = Number.MAX_SAFE_INTEGER;
      }
    });

    this.options.sort((a, b) => {
      return a.relativeDistance - b.relativeDistance;
    });

    let nearestCount = 0;

    this.options.forEach(option => {
      if (nearestCount < this.NEAREST_COUNT) {
        option.group = Labels.NEARBY_LABEL;
        option.nearest = nearestCount + 1;
      } else {
        option.group = Labels.OTHER_LABEL;

        if (sortType === SortType.ALPHABETICALLY) {
          option.nearest = this.NEAREST_COUNT + 1;
        } else {
          option.nearest = nearestCount + 1;
        }
      }

      nearestCount++;
    });

    this.options.sort((a, b) => {
      if (a.nearest > b.nearest) {
        return 1;
      }

      if (a.nearest < b.nearest) {
        return -1;
      }

      if (sortType === SortType.ALPHABETICALLY) {
        if (a.label < b.label) {
          return -1;
        }

        return 1;
      }

      return 0;
    });
  }
}
