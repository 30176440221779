import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { IsDevice } from '../../../../decorators/is-device.decorator';
import { ModalClose } from '../../../../decorators/modal-close.decorator';
import { CountryWithChild } from '../../../../domains/country-with-child';
import { DeviceType } from '../../../../models/enums/device-type';
import { SearchFiltersPosition } from '../../../../models/enums/search-filters-position';
import { Place } from '../../../../models/place';
import { SearchFiltersPositionWrapper } from '../../../../models/wrappers/search-filters-position.wrapper';
import { SearchCityService } from '../../../../services/search-city.service';
import { HashUtils } from '../../../../utils/hash-utils';

@Component({
  selector: 'app-search-location-modal',
  templateUrl: './search-location-modal.component.html',
  styleUrls: ['./search-location-modal.component.scss']
})
export class SearchLocationModalComponent implements OnInit, OnDestroy {
  @Output() selectPlace = new EventEmitter<Place>();
  @Output() findMe = new EventEmitter<boolean>();
  @Output() selectRestaurant = new EventEmitter<boolean>();

  @ViewChild('cityInput', { static: false }) searchCityInput!: ElementRef;

  @IsDevice(DeviceType.MOBILE) isMobile: boolean;

  country: CountryWithChild;
  searchValueExists = false;
  hash: string;
  searchFiltersPositionMobile = new SearchFiltersPositionWrapper(SearchFiltersPosition.MOBILE);
  countrySubscription: Subscription;

  constructor(private searchCityService: SearchCityService) {
  }

  ngOnInit(): void {
    this.hash = HashUtils.getCodePositionUniqueHash();

    this.countrySubscription = this.searchCityService.countrySubject.subscribe(country => {
      this.country = country;
    });
  }

  ngOnDestroy() {
    this.countrySubscription.unsubscribe();
  }

  searchInputValue(searchValue: string) {
    this.searchValueExists = searchValue !== '';
  }

  onSelectPlace(place: Place) {
    this.selectPlace.emit(place);
  }

  onFindMe() {
    this.findMe.emit(true);
  }

  @ModalClose()
  goBack() {
  }

  onSelectRestaurant($event: boolean) {
    this.selectRestaurant.emit($event);

    this.goBack();
  }
}
