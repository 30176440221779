import { Location } from '@angular/common';
import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import Swiper, { SwiperOptions } from 'swiper';
import { environment } from '../../../../../environments/environment';
import { CurrentSearchParams } from '../../../../decorators/current-search-params.decorator';
import { CurrentUser } from '../../../../decorators/current-user.decorator';
import { Food } from '../../../../domains/food';
import { Menu } from '../../../../domains/menu';
import { Restaurant } from '../../../../domains/restaurant';
import { User } from '../../../../domains/user';
import { Basket } from '../../../../models/basket/basket';
import { ModalType } from '../../../../models/enums/modal-type';
import { TagType } from '../../../../models/enums/tag-type';
import { ViewType } from '../../../../models/enums/view-type';
import { Modal } from '../../../../models/modal';
import { RestaurantRouteParams } from '../../../../models/route-params/restaurant-route-params';
import { SearchParams } from '../../../../models/search-params';
import { SearchResult } from '../../../../models/search-result';
import { UnsplashImage } from '../../../../models/unsplash-image';
import { BasketService } from '../../../../services/basket.service';
import { ExploreService } from '../../../../services/explore.service';
import { MenuService } from '../../../../services/menu.service';
import { ModalService } from '../../../../services/modal.service';
import { NavigatorService } from '../../../../services/navigator.service';
import { NotificationService } from '../../../../services/notification.service';
import { RouterService } from '../../../../services/router.service';
import { SearchParamsService } from '../../../../services/search-params.service';
import { SeoService } from '../../../../services/seo.service';
import { UnsplashService } from '../../../../services/unsplash.service';
import { ViewService } from '../../../../services/view.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnChanges, OnDestroy {
  @Input() menuInput: Menu;
  @Input() listView = true;

  @Input() restaurant: Restaurant;

  @ViewChild('previewModalRef') previewModalRef: ElementRef;

  @CurrentUser() currentUser: User;

  @CurrentSearchParams() searchParams: SearchParams;

  restaurantRouteParams: RestaurantRouteParams;
  basket: Basket;
  menus: Menu[] = [];
  viewType = ViewType;
  tagType = TagType;
  categoriesExpanded = true;
  isFoodFound = false;
  shouldShowFood = false;
  restaurantPath: string;
  backgroundImage: UnsplashImage;
  previewModal: Modal;
  exploreBackgroundImage: string;
  subscription: Subscription;
  isHoursEnabled = true;
  hasOnlyDefaultMenu = false;
  user: User;
  searchResult: SearchResult;

  constructor(
    private menuService: MenuService,
    private basketService: BasketService,
    private notificationService: NotificationService,
    private navigatorService: NavigatorService,
    private _location: Location,
    private seoService: SeoService,
    private modalService: ModalService,
    private routerService: RouterService,
    private unsplashService: UnsplashService,
    private viewService: ViewService,
    private searchParamsService: SearchParamsService,
    private exploreService: ExploreService
  ) {
  }

  ngOnInit() {
    this.unsplashService.randomPhoto(true).subscribe(value => {
      this.backgroundImage = value;
    });

    this.exploreBackgroundImage = environment.favoritesBackgroundImage;

    this.subscription = this.routerService.restaurantRouteParamsSubject.subscribe(restaurantRouteParams => {
      this.restaurantRouteParams = restaurantRouteParams;

      if (restaurantRouteParams) {
        if (this.menuInput) {
          this.categoriesExpanded = true;
        }

        this.basket = this.basketService.basket;

        this.menus = this.menuService.getMenus(this.restaurant);
        this.hasOnlyDefaultMenu = this.menus.length === 1 && this.menus[0].default;

        const view = ViewType.COLUMNS;

        this.menus.forEach(menu => {
          menu.setViewType(view);
        });

        if (this.restaurantRouteParams.foodWithIdSlug) {
          this.shouldShowFood = true;

          for (const menu of this.menus) {
            for (const category of menu.categories) {
              for (const food of category.foods) {
                if (
                  food.id === this.restaurantRouteParams.foodId ||
                  food.slug === this.restaurantRouteParams.foodWithIdSlug
                ) {
                  this.isFoodFound = true;
                  this.toView(menu, food, ViewType.CLASSIC, true);
                  break;
                }
              }
            }
          }
        }

        if (!this.isFoodFound && this.menuInput) {
          const menuActiveElement = document.querySelector('#menu-' + this.menuInput.id);
          menuActiveElement?.scrollIntoView({block: 'start', inline: 'nearest'});
        }

        setTimeout(this.changeBasketColor);
      }
    });

    this.searchParamsService.getByRestaurantId(this.restaurant.id).subscribe(searchParams => {
      this.exploreService.search(searchParams).subscribe(value => {
        this.searchResult = value;
      });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.restaurantPath = this.routerService.restaurantBasePath;

    if (changes.hasOwnProperty('listView')) {
      this.categoriesExpanded = true;

      this.switchView(changes.listView.currentValue);
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  toggleMenuCategories(menu: Menu) {
    this.categoriesExpanded = !this.categoriesExpanded;

    setTimeout(() => {
      this.scrollToMenu(menu.slug);
    }, 0);
  }

  toView(menu: Menu, food: Food, newView: ViewType, init = false) {
    if (!init) {
      const restaurantRouteParams = this.routerService.restaurantRouteParams;

      if (newView === ViewType.CLASSIC) {
        if (!restaurantRouteParams.hasMenu) {
          restaurantRouteParams.menuSlug = menu.slug;
        }

        if (!restaurantRouteParams.hasFood) {
          if (!food.gallery) {
            restaurantRouteParams.setFood(food.slug + '_' + food.id);
          } else {
            restaurantRouteParams.setFood(food.slug);
          }
        }

        this.navigatorService.changeLocationTo(this.restaurantPath + food.profileUrl);
      } else {
        if (restaurantRouteParams.hasFood) {
          restaurantRouteParams.removeFood();
        }

        this.navigatorService.changeLocationTo(`${this.restaurantPath}/${menu.slug}`);
      }
    }

    if (newView === ViewType.CLASSIC) {
      this.seoService.setFoodMetaTags(this.restaurant, food);
    } else {
      this.seoService.setRestaurantMetaTags(this.restaurant);
    }

    this.viewService.setViewTypeAndScrollToPhantomView(food.view, newView);
  }

  addToBasket(food: Food, categoryId: number) {
    if (food.hasSelections) {
      this.basket.addFood(food, categoryId);
      this.basketService.basket = this.basket;
    } else {
      setTimeout(() => {
        this.notificationService.makeSelection();
      }, 100);
    }
  }

  switchView(listView: boolean) {
    const currentMenuSlug = this._location.path().split((this.restaurantPath + '/'))[1];

    this.listView = listView;
    const view = this.listView ? ViewType.LIST : ViewType.COLUMNS;

    this.changeBasketColor();

    this.menus.forEach(menu => {
      menu.setViewType(view);
    });

    setTimeout(() => {
      if (!this.isFoodFound) {
        this.scrollToMenu(currentMenuSlug);
      }
    }, 0);
  }

  changeBasketColor() {
    const basket = document.querySelector('.basket-short');
    const listBasketClass = 'list-basket';

    if (this.listView) {
      basket?.classList.add(listBasketClass);
    } else {
      basket?.classList.remove(listBasketClass);
    }
  }

  private scrollToMenu(slug: string) {
    let offset = -74;

    if (slug === document.querySelector('.menu')?.getAttribute('data-slug')) {
      offset = -90;
    }

    const scrollElement = document.querySelector('html');

    if (this.categoriesExpanded) {
      const element = document.querySelector(`.menu[data-slug="${slug}"]`);

      const y = element?.getBoundingClientRect().top + window.scrollY + offset;

      scrollElement.scrollTo({top: y, behavior: 'auto'});
    } else {
      scrollElement.scrollTo({top: 0, behavior: 'smooth'});
    }
  }

  openPreviewModal() {
    this.previewModal = this.modalService.open(this.previewModalRef, ModalType.PREVIEW_SWIPER);
  }

  onSlideChange($event: [swiper: Swiper]) {
    if ($event[0].activeIndex !== 1) {
      this.previewModal.close();
    }
  }
}
