import { LocalStorage } from '../local-storage/local-storage';
import { LocalStorageKey } from '../models/enums/local-storage-key';
import { SearchParams } from '../models/search-params';

export function CurrentSearchParams(): PropertyDecorator {
  return (target: any, propertyKey: string) => {
    const searchParamsLocalStorage = new LocalStorage(SearchParams, LocalStorageKey.SEARCH_PARAMS);

    const getter = () => {
      return searchParamsLocalStorage.getItem();
    };

    const setter = () => {
    };

    Object.defineProperty(target, propertyKey, {
      get: getter,
      set: setter
    });
  };
}
