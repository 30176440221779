<label *ngIf="position.isMobile" class="search-filters-mobile-label mb-0 d-block position-relative mr-2">
  <select
    required
    aria-label="State"
    class="custom-select custom-select-mobile d-block hide-arrow"
    formControlName="stateId"
    (change)="onStateChange($event.target)"
  >
    <option value="null" hidden [selected]="!stateSelected">State</option>

    <ng-container *ngIf="countrySelected">
      <option *ngFor="let option of stateSelect.options" [value]="option.value">
        {{ option.label }}
      </option>
    </ng-container>
  </select>
</label>

<label *ngIf="position.isDesktopNavbar" class="mb-0 text-left mr-3">
  <ng-select
    appNgSelectOnHover
    [items]="stateSelect.options"
    [searchable]="false"
    [ngClass]="'ng-select-explore-search-title-under-logo dark'"
    class="ng-select-explore ng-select-dropdown auto-grow hide-arrow"
    bindLabel="label"
    bindValue="value"
    placeholder="State"
    formControlName="stateId"
    (change)="onStateChange($event)"
  ></ng-select>
</label>
