import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Auth } from '../../../decorators/auth.decorator';
import { CurrentUser } from '../../../decorators/current-user.decorator';
import { Subscribe } from '../../../decorators/subscribe.decorator';
import { Food } from '../../../domains/food';
import { User } from '../../../domains/user';
import { CookieKey } from '../../../models/enums/cookie-key';
import { Image } from '../../../models/image';
import { LoaderService } from '../../../services/loader.service';
import { NotificationService } from '../../../services/notification.service';

@Component({
  selector: 'app-navbar-bottom-user',
  templateUrl: './navbar-bottom-user.component.html',
  styleUrls: [
    '../navbar.shared.scss'
  ]
})
export class NavbarBottomUserComponent implements OnInit {
  @Input() user: User;
  @Input() isOwner: boolean;
  @Input() food: Food;
  @Input() isExampleRestaurant: boolean;
  @Input() itemGallery = false;
  @Input() profilePage = false;

  @Output() chatClick = new EventEmitter<boolean>();
  @Output() imageUploaded = new EventEmitter<Image>();
  @Output() subscriptionModalOpen = new EventEmitter<boolean>();

  @CurrentUser() currentUser: User;

  constructor(
    private cookieService: CookieService,
    private notificationService: NotificationService,
    private loaderService: LoaderService,
  ) {
  }

  ngOnInit() {
    setTimeout(() => {
      if (this.cookieService.get(CookieKey.SHOW_IMAGE_SELECT_MODAL) && this.user && this.user.igAccessToken) {
        this.cookieService.delete(CookieKey.SHOW_IMAGE_SELECT_MODAL);

        this.onMainMenuClick();
      }
    }, 1000);
  }

  @Auth()
  onMainMenuClick() {
    if (this.profilePage) {
      this.openMembershipModal();
    } else if (!this.food) {
      this.subscriptionModalOpen.emit(true);
    } else if (this.isExampleRestaurant && this.currentUser.id !== 10) {
      this.notificationService.galleryUploadDisabled();
    }
  }

  onImageUploaded(image: Image) {
    this.loaderService.hideLoader();

    this.imageUploaded.emit(image);
  }

  @Auth()
  @Subscribe()
  openMembershipModal() {
  }
}
