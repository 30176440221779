<div class="semicircle-notch-menu">
  <div class="semicircle-notch">
    <span class="circle"></span>
  </div>

  <div class="semicircle-inset flex-content-vc-hc">
    <a
      [ngClass]="{'btn-primary': isPrimary}"
      class="btn btn-default btn-circle btn-circle-lg flex-content-vc-hc"
      (click)="onInsetClick()"
    >
      <ng-content></ng-content>
    </a>
  </div>
</div>
