<app-search-desktop-topnav-loader *ngIf="isSearching"></app-search-desktop-topnav-loader>

<app-desktop-topnav *ngIf="!isSearching">
  <div class="d-flex justify-content-between w-100">
    <div class="search-desktop-topnav-left flex-content-vc">
      <a
        appHrefNone
        class="icon-v2 mmmm-red scale-09 mx-3"
        (mouseover)="logoMouseOver = true"
        (mouseleave)="logoMouseOver = false"
      ></a>

      <app-search-location></app-search-location>
    </div>

    <div class="search-desktop-topnav-right flex-content-vc">
      <div class="d-flex align-items-center">
        <app-search-filters
          [country]="country"
          [isHovered]="isHovered"
          [preview]="preview"
          [hideTemporary]="true"
          [position]="searchFiltersPositionUnderLogo"
          (citySelectedChange)="onCitySelected($event)"
          (previewChange)="onPreviewChange()"
          (locationDropdownChange)="onLocationDropdownChange()"
          (localitySelectedChange)="onLocalitySelectedChange($event)"
          (stateSelectedChange)="onStateChange($event)"
          (countySelectedChange)="onCountyChange($event)"
          class="ml-1-8rem"
        ></app-search-filters>

        <div
          [ngClass]="{'opacity-0': isSidenavLeftOrRightMouseOver}"
          class="search-desktop-filters-city ml-0-7rem"
        >
          <app-distance-filter
            *ngIf="searchParams?.geoLocation"
            [searchResult]="searchResult"
          ></app-distance-filter>
        </div>
      </div>

      <div class="flex-content-vc-hr flex-fill">
        <a
          *ngIf="isLoggedIn"
          appHrefNone
          [ngClass]="{
            'mt-0-4rem': locationFavorites?.favoritesCount || locationFavorites?.tryMeCount,
          }"
          (click)="onFavoriteClick()"
        >
          <div
            [ngClass]="{
              'icon call': !locationFavorites?.favoritesCount && !locationFavorites?.tryMeCount,
              'goal-red': locationFavorites?.tryMeCount,
              'heart-red': locationFavorites?.favoritesCount && !locationFavorites?.tryMeCount
            }"
            class="cursor-pointer icon-v2"
          ></div>
        </a>

        <a
          appHrefNone
          class="icon-v2 play cursor-pointer ml-2rem mb-0-35rem"
          (click)="openVideoModal()"
        ></a>

        <a
          class="icon-v2 profile-dark cursor-pointer ml-2rem"
          (click)="goToUser()"
        ></a>

        <a class="icon-v2 hamburger-dark cursor-pointer ml-2rem mr-2rem"></a>
      </div>
    </div>
  </div>
</app-desktop-topnav>
