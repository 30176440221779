<div id="mmmm-loader"></div>
<div id="layout-background" [ngClass]="{'kenburns-top': isSidenavLeftOrRightMouseOver}"></div>

<router-outlet></router-outlet>

<app-search-location *ngIf="isMobile"></app-search-location>

<ng-template #loginModalRef>
  <app-user-authorization></app-user-authorization>
</ng-template>

<ng-template #subscribeModalRef>
  <app-subscribe-modal></app-subscribe-modal>
</ng-template>

<ng-template #subscribeDesktopModal>
  <app-membership-desktop></app-membership-desktop>
</ng-template>
