import { Component, OnInit } from '@angular/core';
import { CurrentSearchParams } from '../../decorators/current-search-params.decorator';
import { SearchParams } from '../../models/search-params';
import { UnsplashImage } from '../../models/unsplash-image';
import { NotificationService } from '../../services/notification.service';
import { UnsplashService } from '../../services/unsplash.service';

@Component({
  selector: 'app-targets',
  templateUrl: './targets.component.html',
  styleUrls: ['./targets.component.scss']
})

export class TargetsComponent implements OnInit {
  @CurrentSearchParams() searchParams: SearchParams;

  backgroundImage: UnsplashImage;

  constructor(
    private notificationService: NotificationService,
    private unsplashService: UnsplashService
  ) {
  }

  ngOnInit(): void {
    this.unsplashService.randomPhoto(true).subscribe(value => {
      this.backgroundImage = value;
    });
  }

  targetClicked() {
    this.notificationService.targetLineClicked();
  }
}

