import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { CurrentSearchParams } from '../../../decorators/current-search-params.decorator';
import { IsDevice } from '../../../decorators/is-device.decorator';
import { LocalStorage } from '../../../local-storage/local-storage';
import { DeviceType } from '../../../models/enums/device-type';
import { LocalStorageKey } from '../../../models/enums/local-storage-key';
import { ModalType } from '../../../models/enums/modal-type';
import { Modal } from '../../../models/modal';
import { Place } from '../../../models/place';
import { SearchParams } from '../../../models/search-params';
import { ModalService } from '../../../services/modal.service';
import { NavigatorService } from '../../../services/navigator.service';
import { SearchCityService } from '../../../services/search-city.service';
import { SearchParamsService } from '../../../services/search-params.service';

@Component({
  selector: 'app-search-location',
  templateUrl: './search-location.component.html',
  styleUrls: ['./search-location.component.scss']
})
export class SearchLocationComponent implements OnInit, OnDestroy {
  @ViewChild('searchCityModalRef') searchCityModalRef: ElementRef;

  @IsDevice(DeviceType.MOBILE) isMobile: boolean;
  @IsDevice(DeviceType.DESKTOP) isDesktop: boolean;

  @CurrentSearchParams() searchParams: SearchParams;

  searchCityModal: Modal;
  cityModalSubjectSubscription: Subscription;
  inputHover: boolean;
  leftInputHover = false;
  rightInputHover = false;
  restaurantInputValue = '';
  cityInputValue = '';
  searchParamsLocalStorage = new LocalStorage(SearchParams, LocalStorageKey.SEARCH_PARAMS);

  constructor(
    private modalService: ModalService,
    private searchCityService: SearchCityService,
    private searchParamsService: SearchParamsService,
    private navigatorService: NavigatorService
  ) {
    this.cityModalSubjectSubscription = this.searchCityService.openSearchCityModalSubject.subscribe(() => {
      this.openSearchCityModal();
    });
  }

  ngOnInit(): void {
    if (this.cityModalSubjectSubscription === undefined) {
      this.cityModalSubjectSubscription = this.searchCityService.openSearchCityModalSubject.subscribe(value => {
        this.openSearchCityModal();
      });
    }
  }

  ngOnDestroy(): void {
    this.cityModalSubjectSubscription?.unsubscribe();
  }

  onRestaurantInput(value: any) {
    this.restaurantInputValue = value;
  }

  onCityInput(value: any) {
    this.cityInputValue = value;
  }

  openSearchCityModal() {
    this.searchCityModal = this.modalService.open(
      this.searchCityModalRef,
      this.isMobile ? ModalType.TOP_SEARCH : ModalType.SEARCH_SQUARE
    );
  }

  onSelectPlace(place: Place) {
    this.searchCityModal?.close();

    this.searchParamsService.getByPlaceId(place.placeId).subscribe(searchParams => {
      this.setSearchParamsAndGoToPath(searchParams);
    });
  }

  onFindMe() {
    this.searchCityModal?.close();
    this.searchCityService.findMeSubject.next(true);

    this.searchParamsService.getByMyGeoLocation(true).then(searchParamsByGeoLocation => {
      this.setSearchParamsAndGoToPath(searchParamsByGeoLocation);
    });
  }

  private setSearchParamsAndGoToPath(searchParams: SearchParams) {
    this.searchParamsLocalStorage.setItem(searchParams);

    this.navigatorService.goToUrl(searchParams.path).then();
  }

  onSelectRestaurant($event: boolean) {
    this.searchCityService.selectRestaurantSubject.next($event);
  }

  setInputHover(hover: boolean) {
    this.inputHover = hover;
    this.onRestaurantInput(hover);
    this.onCityInput(hover);
  }
}
