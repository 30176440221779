import { Component, Input, OnInit } from '@angular/core';
import { CurrentSearchParams } from '../../../decorators/current-search-params.decorator';
import { DistanceSliderPosition } from '../../../models/distance-slider-position';
import { SearchParams } from '../../../models/search-params';
import { SearchResult } from '../../../models/search-result';
import { SearchCityService } from '../../../services/search-city.service';
import { SearchParamsService } from '../../../services/search-params.service';

@Component({
  selector: 'app-navbar-top-explore',
  templateUrl: './navbar-top-explore.component.html',
  styleUrls: [
    '../navbar.shared.scss',
    './navbar-top-explore.component.scss'
  ]
})
export class NavbarTopExploreComponent implements OnInit {
  @Input() findMeLoader: boolean;
  @Input() searchResult: SearchResult;

  @CurrentSearchParams() searchParams: SearchParams;

  distanceSliderPosition = DistanceSliderPosition;

  constructor(
    private searchParamsService: SearchParamsService,
    private searchCityService: SearchCityService
  ) {
  }

  ngOnInit() {
  }

  onNavbarClick() {
    this.searchCityService.openSearchCityModalSubject.next(true);
  }

  clearGeoLocation(event: Event) {
    event.preventDefault();
    event.stopPropagation();

    this.searchParamsService.clearGeoLocationSubject.next(true);
  }
}
