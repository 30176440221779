import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-navbar-bottom-semicircle-notch',
  templateUrl: './navbar-bottom-semicircle-notch.component.html',
  styleUrls: ['./navbar-bottom-semicircle-notch.component.scss']
})
export class NavbarBottomSemicircleNotchComponent implements OnInit {
  @Input() isPrimary = false;

  @Output() insetClick = new EventEmitter<boolean>();

  constructor() {
  }

  ngOnInit(): void {
  }

  onInsetClick() {
    this.insetClick.emit(true);
  }
}
