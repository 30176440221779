import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { CurrentSearchParams } from '../../../../decorators/current-search-params.decorator';
import { ModalClose } from '../../../../decorators/modal-close.decorator';
import { Chain } from '../../../../domains/chain';
import { SearchParams } from '../../../../models/search-params';
import { TypeCategorySection } from '../../../../models/type-category-section';
import { UnsplashImage } from '../../../../models/unsplash-image';
import { ChainService } from '../../../../services/chain.service';
import { NavigatorService } from '../../../../services/navigator.service';
import { UnsplashService } from '../../../../services/unsplash.service';

@Component({
  selector: 'app-chain-modal-desktop',
  templateUrl: './chain-modal-desktop.component.html',
  styleUrls: ['./chain-modal-desktop.component.scss']
})
export class ChainModalDesktopComponent implements OnInit {
  @Input() typeCategorySection: TypeCategorySection;
  @Input() cityName: string;
  @Input() hasNext: boolean;
  @Input() hasPrev: boolean;

  @Output() previousClick = new EventEmitter<boolean>();
  @Output() nextClick = new EventEmitter<boolean>();

  @CurrentSearchParams() searchParams: SearchParams;

  backgroundImage: UnsplashImage;
  chains: Array<Chain> = [];

  constructor(
    private unsplashService: UnsplashService,
    private navigatorService: NavigatorService,
    private chainService: ChainService
  ) {
  }

  ngOnInit(): void {
    this.unsplashService.randomPhoto().subscribe(value => {
      this.backgroundImage = value;
    });

    this.chainService.getAllByTypeCategorySection(this.typeCategorySection).subscribe(chains => {
      this.chains = chains;
    });
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.code === 'ArrowLeft') {
      this.previousClick.emit(true);
    } else if (event.code === 'ArrowRight') {
      this.nextClick.emit(true);
    }
  }

  onChainClick(chain: Chain) {
    this.navigatorService.goToMaps(chain.title + ', ' + this.searchParams.locationNameAndStateKey);
  }

  @ModalClose()
  closeModal() {
  }
}
