import { ElementRef, Injectable } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';

@Injectable({providedIn: 'root'})
export class SelectService {
  constructor() {
  }

  toggleSelect(select: ElementRef, disable: boolean) {
    if (select !== undefined) {
      select.nativeElement.disabled = disable;
    }
  }

  toggleNgSelectComponent(ngSelectComponent: NgSelectComponent, disable: boolean) {
    if (ngSelectComponent !== undefined) {
      ngSelectComponent.setDisabledState(disable);

      const disabledClass = 'ng-select-disabled';

      if (disable) {
        ngSelectComponent.element.classList.add(disabledClass);
      } else {
        ngSelectComponent.element.classList.remove(disabledClass);
      }
    }
  }
}
