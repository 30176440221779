<div class="navbar-container max-width fixed-bottom d-flex safe-area-bottom">
  <nav class="navbar navbar-bottom bg-white flex-fill pr-0">
    <app-picker
      [classes]="'icon chains-dark scale-1_07 w-31px'"
      [picker]="searchResult.chainsPicker"
      (selectedValue)="onChainsPickerSelected($event)"
    ></app-picker>

    <div
      *ngIf="isLoggedIn"
      [ngClass]="{
        'icon call': !locationFavorites?.favoritesCount && !locationFavorites?.tryMeCount,
        'goal-red': locationFavorites?.tryMeCount,
        'heart-red': locationFavorites?.favoritesCount && !locationFavorites?.tryMeCount
      }"
      class="icon-v2 w-31px"
      (click)="onFavoritesClick()"
    ></div>

    <span
      *ngIf="!isLoggedIn"
      class="icon-v2 play mb-1 w-31px"
      (click)="onVideoClick()"
    ></span>

    <div class="visibility-hidden"></div>
  </nav>

  <app-navbar-bottom-semicircle-notch [isPrimary]="canEditHours">
    <span *ngIf="canEditHours">
      <a
        *ngIf="userRestaurants.length == 1"
        appHrefNone
        class="icon-v2 clock scale-1_2 pt-1-8rem"
        (click)="openHoursEditModal(userRestaurants[0])"
      ></a>
    </span>

    <app-navbar-bottom-restaurants-picker
      *ngIf="!canEditHours"
      [searchResult]="searchResult"
      (restaurantSelected)="onRestaurantsPickerSelected($event)"
    ></app-navbar-bottom-restaurants-picker>
  </app-navbar-bottom-semicircle-notch>

  <nav class="navbar navbar-bottom bg-white flex-fill pl-0">
    <div class="visibility-hidden"></div>

    <app-navbar-bottom-icon-search></app-navbar-bottom-icon-search>

    <app-navbar-bottom-icon-user></app-navbar-bottom-icon-user>
  </nav>
</div>

<ng-template #hoursEditModalRef let-c="close">
  <app-hours-edit-modal
    [restaurant]="restaurant"
    [explore]="true"
    (editRestaurantMenus)="editRestaurantMenus($event)"
  ></app-hours-edit-modal>
</ng-template>
