import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CurrentSearchParams } from '../../../../decorators/current-search-params.decorator';
import { RestaurantExplore } from '../../../../domains/restaurant-explore';
import { CustomOption } from '../../../../models/enums/custom-option';
import { SearchParams } from '../../../../models/search-params';
import { SearchResult } from '../../../../models/search-result';
import { SearchCityService } from '../../../../services/search-city.service';

@Component({
  selector: 'app-navbar-bottom-restaurants-picker',
  templateUrl: './navbar-bottom-restaurants-picker.component.html',
  styles: [
  ]
})
export class NavbarBottomRestaurantsPickerComponent {
  @Input() searchResult: SearchResult;

  @Output() restaurantSelected = new EventEmitter<RestaurantExplore>();

  @CurrentSearchParams() searchParams: SearchParams;

  constructor(private searchCityService: SearchCityService) {
  }

  onPickerSelected($event: RestaurantExplore | CustomOption) {
    if ($event instanceof RestaurantExplore) {
      this.restaurantSelected.emit($event);
    } else if ($event === CustomOption.SEARCH) {
      this.searchCityService.openSearchCityModalSubject.next(true);
    }
  }
}
