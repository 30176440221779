import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CurrentSearchParams } from '../../../../decorators/current-search-params.decorator';
import { RestaurantSimple } from '../../../../domains/restaurant-simple';
import { LocationFavorites } from '../../../../models/location-favorites';
import { SearchParams } from '../../../../models/search-params';
import { SearchResult } from '../../../../models/search-result';
import { LoaderService } from '../../../../services/loader.service';

@Component({
  selector: 'app-search-modal-favorites-slide',
  templateUrl: './search-modal-favorites-slide.component.html',
  styleUrls: ['./search-modal-favorites-slide.component.scss']
})
export class SearchModalFavoritesSlideComponent implements OnInit, OnChanges {
  @Input() locationFavorites: LocationFavorites;
  @Input() searchResult: SearchResult;

  @Output() setPreviewSlideRestaurant = new EventEmitter<RestaurantSimple>();
  @Output() restaurantNameClick = new EventEmitter<boolean>();
  @Output() favoriteStatusChanged = new EventEmitter<boolean>();

  @CurrentSearchParams() searchParams: SearchParams;

  constructor(
    private loaderService: LoaderService
  ) {
    this.loaderService.showLoader();
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.loaderService.hideLoader();
    }, 500);
  }

  onSetPreviewSlideRestaurant($event: RestaurantSimple) {
    this.setPreviewSlideRestaurant.emit($event);
  }

  onRestaurantNameClick() {
    this.restaurantNameClick.emit(true);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('searchParams')) {
      this.searchParams = changes['searchParams'].currentValue;
    }
  }

  onFavoriteStatusChange() {
    this.favoriteStatusChanged.emit(true);
  }
}
